import { AlertContainer } from 'react-alert';

import { BRANDING } from './branding';

export type ErrorType = {
  type: string;
  message: string;
};

export const digError = (obj?: ErrorType) => (obj ? obj.message : '');

export const parseGraphQLError = (
  graphQLErrors: any,
  separator: string = ', ',
) => {
  const hasError = graphQLErrors && graphQLErrors.length;
  if (hasError) {
    return graphQLErrors.map((e: ErrorType) => e.message).join(separator);
  }

  return `There was an error. Either try again or contact ${BRANDING.supportEmail}`;
};

export const showGraphQLError = (alert: AlertContainer, graphQLErrors: any) => {
  alert.error(parseGraphQLError(graphQLErrors, '<br />'));
};

export const processError = (
  obj: ErrorType,
  labelText: string = 'This field',
  value: any = null,
) => {
  if (obj) {
    switch (obj.type) {
      case 'required':
        return `${labelText} is required`;
      case 'validate_date':
        return `${labelText} is not a valid date`;
      case 'validate':
        return `${labelText} is invalid`;
      case 'pattern':
        return `${labelText} is not a valid format`;
      case 'min':
        return `${labelText} must be at least ${value}`;
      default:
        return obj.message;
    }
  }
  return null;
};
